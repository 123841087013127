import React from "react";
import { ResourceConsumerWithResource } from "../../pages/learningLibrary/learningLibraryTypes";
import generateVideoJsOptions from "../generateVideoJsOptions";
import isVideoJSEnabled from "../isVideoJSEnabled";
import VideoPlayer from "../videojs_player";
import { VimeoPlayer } from "../vimeo_player";
import { YoutubePlayer } from "../youtube_player";
import PdfViewer from "./pdfViewer";
import replaceShortCodes from "./replaceShortCodes";
import { RenderResourceOpts } from "./resourceTypes";
import Audio from "../audio_player";

const renderVideoResource = (
  resourceConsumer: ResourceConsumerWithResource,
  resourceProgressMetadata: any,
  setResourceProgressMetadata: any,
  opts?: RenderResourceOpts
) => {
  const options = generateVideoJsOptions(
    resourceConsumer,
    false,
    opts?.autoplay
  );

  return (
    <VideoPlayer
      key={resourceConsumer.id + "video"}
      containerClassName={opts?.videoClasses}
      options={options}
      resourceId={resourceConsumer.resource_id}
      noteable={opts?.noteable === false ? false : true}
      trackProgress={opts?.trackProgress === false ? false : true}
      metadata={resourceProgressMetadata}
      setMetadata={(args: any) => {
        setResourceProgressMetadata({
          resourceConsumerId: resourceConsumer.id,
          ...args,
        });
      }}
    />
  );
};

const renderResource = (
  resource: ResourceConsumerWithResource,
  resourceProgressMetadata: any,
  setResourceProgressMetadata: any,
  opts?: RenderResourceOpts
) => {
  switch (resource.type) {
    case "video_resource":
      return renderVideoResource(
        resource,
        resourceProgressMetadata,
        setResourceProgressMetadata,
        opts
      );
    case "vimeo_resource":
      if (isVideoJSEnabled(resource)) {
        return renderVideoResource(
          resource,
          resourceProgressMetadata,
          setResourceProgressMetadata,
          opts
        );
      } else {
        return (
          <VimeoPlayer
            className="h-[20vh] md:h-[40vh] lg:h-[30vh] xl:h-[50vh] xxl:h-[70vh] w-full"
            title={resource.name || ""}
            src={resource.url}
            trackProgress={true}
            metadata={resourceProgressMetadata}
            setMetadata={(args: any) =>
              setResourceProgressMetadata({
                ...resourceProgressMetadata,
                resourceConsumerId: resource.id,
                ...args,
              })
            }
          />
        );
      }
    case "youtube_resource":
      return (
        <YoutubePlayer
          className="h-[20vh] md:h-[40vh] lg:h-[30vh] xl:h-[50vh] xxl:h-[70vh] w-full"
          title={resource.name || ""}
          src={resource.url}
          metadata={resourceProgressMetadata}
          setMetadata={(args: any) =>
            setResourceProgressMetadata({
              ...resourceProgressMetadata,
              resourceConsumerId: resource.id,
              ...args,
            })
          }
        />
      );
    case "image_resource":
      return (
        <div className="flex justify-center">
          <img
            alt={`image resource ${resource.name}`}
            src={resource.url}
            className={opts?.imageClasses ? opts.imageClasses : "h-[70vh]"}
          />
        </div>
      );
    case "pdf_resource": {
      return <PdfViewer resource={resource} key={`${resource.id}_pdf`} />;
    }
    case "richtext": {
      const alignment = opts?.alignWithVideo ? "mx-auto" : "";

      return (
        <div
          className={`max-w-prose ${alignment}`}
          dangerouslySetInnerHTML={{
            __html: replaceShortCodes((resource.metadata as any)?.value),
          }}
        />
      );
    }
    case "audio_resource":
      return <Audio source={resource?.url || ""} />;
    default:
      console.log("unknown resource type", resource.type);
  }
};

export default renderResource;
