import React, { useEffect, useState } from "react";
import { VimeoPlayer } from "../../../components/vimeo_player";
import { definitions } from "../../../../types/supabase";
import { brandConfig } from "../../../helpers/brandConfig";
import VideoPlayer from "../../../components/videojs_player";
import isVideoJSEnabled from "../../../components/isVideoJSEnabled";
import generateVideoJsOptions from "../../../components/generateVideoJsOptions";

const Thumbnail = ({
  primaryResource,
  isHovering = false,
  className = "flex items-center",
}: {
  primaryResource?: definitions["resources"];
  isHovering?: boolean;
  className?: string;
}) => {
  const [player, setPlayer] = useState<any>(null);
  const isVideojs =
    primaryResource?.type === "video_resource" ||
    isVideoJSEnabled(primaryResource);

  useEffect(() => {
    (async () => {
      if (!player) return;
      if (isVideojs) {
        if (isHovering) {
          if (player.paused() && player.readyState() === 4) player.play();
        } else {
          if (!player.paused()) player.pause();
        }
        // if thumbnail for a vimeo resource
      } else {
        const isPaused = await player.getPaused();
        if (isHovering) {
          const duration = await player.getDuration();
          // jump to 20% of the video, probably more interesting than the same intro
          player.setCurrentTime(duration * 0.2);
          if (isPaused) await player.play();
        } else {
          if (!isPaused) await player.pause();
        }
      }
    })();
  }, [isHovering]);

  let child = null;
  const videojsChild = () => (
    <VideoPlayer
      containerClassName="items-center h-48 w-full rounded mb-2 md:mb-0 md:h-8 md:w-16 overflow-hidden object-cover"
      options={generateVideoJsOptions(primaryResource, true)}
      resourceId={primaryResource?.id}
      noteable={false}
      passPlayerRef={(player: any) => setPlayer(player)}
    />
  );
  switch (primaryResource?.type) {
    case "video_resource":
      child = videojsChild();
      break;
    case "vimeo_resource":
      if (isVideoJSEnabled(primaryResource)) {
        child = videojsChild();
      } else {
        child = (
          <VimeoPlayer
            title={"Video"}
            src={primaryResource.url}
            background={true}
            metadata={{}}
            className="my-auto h-8 w-16"
            setMetadata={() => {}}
            passPlayerRef={(player) => setPlayer(player)}
          />
        );
      }
      break;
    case "image_resource":
      child = (
        <img
          src={primaryResource.url}
          className="mx-auto md:my-auto object-contain md:object-cover h-48 w-full md:h-8 md:w-16 rounded md:shadow flex"
        />
      );
      break;
    default:
      child = (
        <img
          src={brandConfig.navbarImage()}
          className="my-auto p-1 h-8 max-w-16 rounded bg-opacity-50"
        />
      );
  }

  return <div className={className}>{child}</div>;
};

export default Thumbnail;
